export const BookingTypes = {
  DEMO: 'demo',
  CLASS: 'class',
  MEMBERSHIP_PREVIEW: 'membership_preview',
  EVENT: 'event',
  EXCEL: 'excel',
  EXPLORATION: 'exploration',
  SUMMER_EXPLORE: 'exploration_camp',
  MEMBERSHIP: 'membership'
}

export const FilterTypes = {
  CENTRE: 'centre',
  GRADE: 'grade',
  CATEGORY: 'category',
  BATCH_TYPE: 'type',
  LEVEL: 'level'
}

export const batchTypes = [
  { uuid: 'explore', name: 'Trial' },
  { uuid: 'excel', name: 'Excel' },
  { uuid: 'exploration', name: 'Exploration' },
  { uuid: 'class', name: 'Regular Non-member' },
  { uuid: 'event', name: 'Event' },
  { uuid: 'exploration_camp', name: ' Summer Explore' }
]

export const levelList = [
  { uuid: '1', name: 'beginner' },
  { uuid: '2', name: 'intermediate' },
  { uuid: '3', name: 'advanced' }
]

export const levelMap = {
  1: 'beginner',
  2: 'intermediate',
  3: 'advanced'
}

export const batchTypesMap = {
  class: 'class',
  explore: 'explore',
  event: 'event',
  excel: 'excel',
  exploration: 'exploration',
  excel: 'excel',
  exploration_camp: 'exploration_camp'
}

export const exploreMessageList = {
  NOT_RELEVANT: 'explore not relevant',
  NOT_EXPLORER_YET: 'not an explorer yet',
  SESSION_NOT_RELEVANT: 'explorer, session not relevant',
  UPCOMING_EXPLORER: 'upcoming explorer'
}

export const batchTypesNewFlow = {
  EXCEL: 'excel'
}

export const conflictTypes = {
  CLASS_CONFLICT: 'class conflict',
  SLOTS_FULL: 'slots full',
  ALREADY_ENROLLED: 'already enrolled',
  MAKEUP_CONFLICT: 'makeup conflict',
  CART_CONFLICT: 'cart conflict',
  LEVEL_CONFLICT: 'level conflict'
}

export const conflictMessages = {
  [conflictTypes.CLASS_CONFLICT]: {
    title: 'Class Conflict',
    text1: 'The child has another class during the same time.',
    text2: 'Please select a different time OR a different child.'
  },
  [conflictTypes.ALREADY_ENROLLED]: {
    title: 'Active Enrolment',
    text1:
      'The child is already enroled for this category.* Please pick a different child.',
    text2:
      '*NOTE: Please make sure that if the child has completed discovery, the report has been submitted. Subscribing is only allowed after the submission.'
  },
  [conflictTypes.SLOTS_FULL]: {
    title: 'Slots Full',
    text1: 'The selected batch is full.',
    text2: 'Please select a different batch.'
  },
  [conflictTypes.MAKEUP_CONFLICT]: {
    title: 'Makeup Conflict',
    text1: 'The child has a makeup session during the same time.',
    text2: 'Please select a different time OR a different child.'
  },
  [conflictTypes.LEVEL_CONFLICT]: {
    title: 'Level Mismatch',
    text2:
      'Please ensure you enrol the child in the level that they are supposed to be in.'
  }
}

export const BOOKING_PAYMENT_TYPES = {
  BOOK_WITH_COINS: 'book_with_coins',
  BOOK_WITH_RUPEES: 'book_with_rupees',
  RECHARGE_AND_PAY: 'recharge_and_pay'
}

export const BOOKING_PAYMENT_CTA_TEXT = {
  [BOOKING_PAYMENT_TYPES.BOOK_WITH_COINS]: 'Book with Coins',
  [BOOKING_PAYMENT_TYPES.BOOK_WITH_RUPEES]: 'Pay with Rupees',
  [BOOKING_PAYMENT_TYPES.RECHARGE_AND_PAY]: 'Recharge & Pay'
}

export const CART_NUDGE_VALUES = {
  IS_DUE_FOR_RECHARGE: 'cart_page_due_for_recharge',
  WILL_BE_DUE_FOR_RECHARGE: 'cart_page_post_booking_rech_will_be_due'
}

export const CART_TOOLTIP_DISCOVERY =
  'This is a pro-rata calculation based on the chosen start date and the total number of sessions the child gets.'

export const CART_TOOLTIP_SUBSCRIPTION =
  'This is a pro-rata calculation only for the first-time booking. It will bring the parent into a monthly auto-booking cycle next month onwards.'
