import styled, { css } from 'styled-components'

export const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  // flex-basis: 75%;
  flex: 0.9;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 12px;
`

export const TimingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0.5;
`

export const OtherDetailsContainer = styled.div`
  display: flex;
  flex: 0.75;
  flex-direction: column;
  justify-content: center;

  & > * {
    margin-bottom: ${(props) =>
      props.$isCheckoutMode ? '2px' : '13px'} !important;
  }

  .MuiOutlinedInput-root {
    width: 130px;
    border-radius: 8px;
    height: 28px;
  }

  .MuiOutlinedInput-input {
    padding: 8px;
    font-size: 12px;
  }

  .MuiListItem-root {
    font-size: 12px;
  }
`

export const DescriptionContainer = styled.div`
  margin: 0 1em;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const RightContainer = styled.div`
  ${({ $isCheckoutMode, $isTrial }) =>
    $isCheckoutMode &&
    !$isTrial &&
    css`
      align-self: flex-end;
    `}

  .MuiIconButton-root {
    padding: 4px 8px;
  }
`

export const ActionButtons = styled.div`
  /* margin-right: 10em; */
`

export const Title = styled.h2`
  margin: 0;
  margin-bottom: 4px;
`

export const SubTitle = styled.h4`
  font-weight: normal;
  margin: 0;
  font-size: 14px;
`

export const ImageContainer = styled.div`
  width: calc(max(3em, 48px));
  height: calc(max(3em, 48px));
  border-radius: 50%;
  overflow: hidden;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
`

export const BodyText = styled.p`
  margin: 0.5em 0 0 0;
  font-size: 14px;
`

export const PriceContainer = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
`
export const CoinsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  font-size: 18px;
  font-family: AvenirDemi;
  background: #fff;
`

export const CoinsAmount = styled.span`
  color: var(--oh-orange);
  border-radius: 24px;
  border: 1px solid var(--oh-orange);
  padding: 0 4px;
  display: flex;
  align-items: center;
`

export const CoinImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 1px;
  margin-top: -1px;
`

export const PriceReqContainer = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: var(--oh-red);
`

export const DetailsContainer = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  font-size: 12px;
`

export const ErrorContainer = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--oh-red);
`

export const PriceDetailsContainer = styled.div`
  text-align: right;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`
