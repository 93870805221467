import AttendancePage from 'app/pages/Attendance'
import FutureAttendancePage from 'app/pages/Attendance/FutureAttendance'
import PastAttendancePage from 'app/pages/Attendance/PastAttendance'
import CentreSchedulePage from 'app/pages/CentreSchedule'
import ClassBatchPage from 'app/pages/Class/Batch'
import ClassBoardPage from 'app/pages/Class/Board'
import ClassCoursePage from 'app/pages/Class/Course'
import ClassEnrolmentPage from 'app/pages/Class/Enrolment'
import ClassExcelEnrolmentPage from 'app/pages/Class/ExcelEnrolment'
import ClassExploreEnrolmentPage from 'app/pages/Class/ExploreEnrolment'
import ClassExploreSessionPage from 'app/pages/Class/ExploreSession'
import ClassInventoryPage from 'app/pages/Class/Inventory'
import ClassMakeUpSessionBookingPage from 'app/pages/Class/MakeUpSession/BookMakeUpSession'
import ClassMakeUpSessionPage from 'app/pages/Class/MakeUpSession/ShowMakeUpSession'
import ClassStandardPage from 'app/pages/Class/Standard'
import ClassSubjectPage from 'app/pages/Class/Subject'
import ClassSubjectCategoryPage from 'app/pages/Class/SubjectCategory'
import ClassTrialSessionsPage from 'app/pages/Class/TrialSessions'
import CommunicationBulkMessagingPage from 'app/pages/Communication/BulkMessaging'
import CentreParentChat from 'app/pages/Communication/Chat/CentreParentChat'
import TeacherParentChat from 'app/pages/Communication/Chat/TeacherParentChat'
import InteractionHistoryPage from 'app/pages/Communication/InteractionHistory'
import TicketPage from 'app/pages/Communication/Ticket'
import CommunityClubPage from 'app/pages/Community/Club'
import CommunityInactiveTribeMembershipPage from 'app/pages/Community/InactiveMembership'
import CommunityTribeMembershipPage from 'app/pages/Community/Membership'
import Moments from 'app/pages/Community/Moments'
import Openbook from 'app/pages/Community/Openbook'
import CommunityWaitlistOthersPage from 'app/pages/Community/Others'
import CommunityWaitlistQueuedPage from 'app/pages/Community/Queued'
import CommunityReferralCodePage from 'app/pages/Community/ReferralCode'
import CommunityClubRegistrationPage from 'app/pages/Community/Registration'
import CommunityTribePage from 'app/pages/Community/Tribe'
import CommunityWaitlistTribeSelectionPage from 'app/pages/Community/TribeSelection'
import ParentDashboardPage from 'app/pages/Dashboard/ParentDashboard'
import TeacherDashboardPage from 'app/pages/Dashboard/Teacherdashboard'
import DemoSchedulingPage from 'app/pages/DemoScheduling'
import DemoSchedulingFormPage from 'app/pages/DemoSchedulingForm'
import EventPage from 'app/pages/Event/Event'
import EventRegistrationPage from 'app/pages/Event/Registration'
import ChildCategoryJourney from 'app/pages/Journey/ChildCategoryJourney'
import JourneySelection from 'app/pages/Journey/JourneySelection'
import LoginPage from 'app/pages/LoginPage'
import MembershipCanceledPage from 'app/pages/Membership/Canceled'
import ParentMembershipPage from 'app/pages/Membership/ParentMembership'
import MembershipPausePage from 'app/pages/Membership/Pause'
import MockTestReportCardPage from 'app/pages/Mock/MockTestReportCard'
import MockPaperPage from 'app/pages/Mock/Paper'
import MockPricingPage from 'app/pages/Mock/Pricing'
import NotFoundPage from 'app/pages/NotFoundPage'
import OrdersPage from 'app/pages/Orders/Orders'
import PaymentDispatchPage from 'app/pages/Payment/Dispatch'
import PaymentDueMemberPage from 'app/pages/Payment/Member/DueMember'
import PaymentPaidMemberPage from 'app/pages/Payment/Member/PaidMember'
import PaymentDuePage from 'app/pages/Payment/NonMember/Due'
import PaymentPaidPage from 'app/pages/Payment/NonMember/Paid'
import PaymentDueRenewalPage from 'app/pages/Payment/Renewal/DueRenewal'
import PaymentPaidRenewalPage from 'app/pages/Payment/Renewal/PaidRenewal'
import PaymentIndependentSummaryPage from 'app/pages/Payment/Summary/Independent'
import PaymentIndependentOngoingSummaryPage from 'app/pages/Payment/Summary/IndependentOngoing'
import PaymentStudentSummaryPage from 'app/pages/Payment/Summary/Student'
import PaymentSuperproviderSummaryPage from 'app/pages/Payment/Summary/Superprovider'
import PaymentTeacherSummaryPage from 'app/pages/Payment/Summary/Teacher'
import PlayCareOrdersPage from 'app/pages/PlayCareOrders/PlayCareOrders'
import PortfolioPage from 'app/pages/Portfolio'
import ExploreSessionPage from 'app/pages/ProgressTracking/ExploreSessions/ExploreSession'
import ReportCardPage from 'app/pages/ReportCard'
import SchedulerPage from 'app/pages/Scheduler'
import OpendaySchedulePage from 'app/pages/Scheduler/OpendaySchedule'
import OpendaySlotsPage from 'app/pages/Scheduler/OpendaySlots'
import Opportunities from 'app/pages/Scheduler/Opportunities'
import SchoolDashboardPage from 'app/pages/School/Dashboard'
import SchoolAttendancePage from 'app/pages/SchoolAttendance'
import SpaceCentrePage from 'app/pages/Space/Centre'
import SpaceRoomPage from 'app/pages/Space/Room'
import UserAdminPage from 'app/pages/User/Admin'
import UserParentPage from 'app/pages/User/Parent'
import UserStudentPage from 'app/pages/User/Student'
import UserSuperproviderPage from 'app/pages/User/Superprovider'
import UserTeacherPage from 'app/pages/User/Teacher'

const RoutePages = {
  LoginPage,

  // users pages
  UserAdminPage,
  UserStudentPage,
  UserTeacherPage,
  UserSuperproviderPage,
  UserParentPage,

  // classes pages,
  ClassBatchPage,
  ClassBoardPage,
  ClassCoursePage,
  ClassEnrolmentPage,
  ClassStandardPage,
  ClassSubjectPage,
  ClassSubjectCategoryPage,
  ClassInventoryPage,
  ClassTrialSessionsPage,
  ClassMakeUpSessionBookingPage,
  ClassMakeUpSessionPage,
  ClassExploreSessionPage,
  ClassExploreEnrolmentPage,
  ClassExcelEnrolmentPage,

  // spaces pages
  SpaceCentrePage,
  SpaceRoomPage,

  //Membership pages
  MembershipCanceledPage,
  MembershipPausePage,
  ParentMembershipPage,

  // payments pages
  PaymentDuePage,
  PaymentPaidPage,
  PaymentDueMemberPage,
  PaymentPaidMemberPage,
  PaymentDueRenewalPage,
  PaymentPaidRenewalPage,
  PaymentTeacherSummaryPage,
  PaymentStudentSummaryPage,
  PaymentSuperproviderSummaryPage,
  PaymentIndependentSummaryPage,
  PaymentIndependentOngoingSummaryPage,
  PaymentDispatchPage,

  // Mock pages
  MockPricingPage,
  MockPaperPage,
  MockTestReportCardPage,

  // community pages
  CommunityClubPage,
  CommunityReferralCodePage,
  CommunityClubRegistrationPage,
  CommunityTribePage,
  CommunityTribeMembershipPage,
  CommunityInactiveTribeMembershipPage,
  CommunityWaitlistQueuedPage,
  CommunityWaitlistTribeSelectionPage,
  CommunityWaitlistOthersPage,
  Moments,
  Openbook,

  // DemoSchedulingPage
  DemoSchedulingPage,
  DemoSchedulingFormPage,

  // communication pages
  CommunicationBulkMessagingPage,
  InteractionHistoryPage,
  TicketPage,
  TeacherParentChat,
  CentreParentChat,

  // event pages
  EventPage,
  EventRegistrationPage,

  // scheduler
  SchedulerPage,
  OpendaySchedulePage,
  OpendaySlotsPage,
  Opportunities,

  //schedule
  CentreSchedulePage,

  // attendance
  AttendancePage,
  PastAttendancePage,
  FutureAttendancePage,

  // reports
  ReportCardPage,

  // assignment upload
  PortfolioPage,

  //parent dashboard page
  ParentDashboardPage,
  TeacherDashboardPage,

  // not found
  NotFoundPage,

  //school dashboard
  SchoolDashboardPage,
  SchoolAttendancePage,

  //progress tracking
  ExploreSessionPage,

  //journey pages
  JourneySelection,
  ChildCategoryJourney,

  //Orders page
  OrdersPage,
  PlayCareOrdersPage
}

export default RoutePages
