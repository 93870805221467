import Dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import { enrolmentTypes } from 'app/config'
import { BOOKING_PAYMENT_TYPES } from 'app/pages/Class/Inventory/constants'
import {
  bookEnrolmentBodyStructure,
  bookEnrolmentWithRechargeBodyStructure,
  categoryCostBodyStructure,
  coinCalculationBodyStructure
} from 'app/pages/Class/Inventory/transformers'
import { sentryLogger } from 'sentry'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, post } from '../request'

const bookingRequestStructure = (type, batch, formData, userData) => ({
  batch: batch.uuid,
  student_type: batch.studentSource.value,
  student_uuid: userData.student_uuid,
  start_date: batch.startDate?.label,
  payment_mode: 'pay_custom_fee',
  type,
  ...(type === enrolmentTypes.CLASS
    ? {
        fee: batch.fee,
        frequency: formData.payment_frequency,
        discount_percent: formData.discountPercentageValue,
        first_invoice: {
          start_date: batch.startDate?.label,
          end_date: Dayjs(batch.startDate?.label)
            .endOf('month')
            .format('YYYY-MM-DD'),
          fee: batch.firstTimeFee
        }
      }
    : type === enrolmentTypes.MEMBERSHIP && batch.type === 'explore'
    ? {
        end_date: batch.startDate?.label
      }
    : null)
})

const membershipBookingRequestStructure = (type, batchInfo, userData) => ({
  batches: !isEmpty(batchInfo)
    ? Object.keys(batchInfo)?.map((batchId) => {
        return {
          batch: batchId,
          student_type: 'openhouse',
          student_uuid: userData.student_uuid,
          type
        }
      })
    : [],
  parent_id: userData.parent,
  book_ongoing: type === 'membership' ? true : false
})

export const fetchInventorySchedules = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.batch}/`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching inventory schedules')
    throw error
  }
}

export const createDemoClassBooking = async ({
  type,
  batchInfo,
  formData,
  userData
}) => {
  try {
    await Promise.all(
      Object.keys(batchInfo).map((batchId) =>
        post(
          apiEndpoints.enrolment,
          bookingRequestStructure(
            type,
            { uuid: batchId, ...batchInfo[batchId] },
            formData,
            userData
          )
        )
      )
    )

    return {
      success: true
    }
  } catch (error) {
    sentryLogger(error, 'error in creating demo class booking')
    throw error
  }
}

export const createClassBooking = async ({ type, batchInfo, userData }) => {
  try {
    await Promise.resolve(
      post(
        `${apiEndpoints.cart}/create/`,
        membershipBookingRequestStructure(type, batchInfo, userData)
      )
    )

    return {
      success: true
    }
  } catch (error) {
    sentryLogger(error, 'error in creating class booking')
    throw error
  }
}

export const getBatchEligibilityForStudent = async ({ student, batch }) => {
  try {
    if (!student || !batch) throw new Error('Student and batch are required')
    const requestUrl = makeRequestUrl(`${apiEndpoints.batch}/eligibility/`)
    const response = await post(requestUrl, { student, batch })

    return response?.data
  } catch (error) {
    sentryLogger(error, 'error in getting batch eligibility for student')
    throw error
  }
}

export const getCoinCalculation = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.coinCalculation}`,
      coinCalculationBodyStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching monthly consumption')
    throw error
  }
}

export const getCategoryCost = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.subscription}calculate_cost/`,
      categoryCostBodyStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching prorata batch cost')
    throw error
  }
}

export const handleBookEnrolment = async (data) => {
  const { payment_type } = data
  try {
    let res
    switch (payment_type) {
      case BOOKING_PAYMENT_TYPES.BOOK_WITH_COINS:
        res = await bookEnrolment({
          ...data,
          payment_mode: 'coins'
        })
        break

      case BOOKING_PAYMENT_TYPES.BOOK_WITH_RUPEES:
        res = await bookEnrolment({
          ...data,
          payment_mode: 'rupees'
        })
        break

      case BOOKING_PAYMENT_TYPES.RECHARGE_AND_PAY:
        res = await bookEnrolmentWithRecharge({
          ...data
        })
        break

      default:
        break
    }

    return res
  } catch (error) {
    sentryLogger(error)
    throw error
  }
}

const bookEnrolment = async (data) => {
  try {
    const makeRequestUrl = `${apiEndpoints.cartV2}`
    const response = await post(
      makeRequestUrl,
      bookEnrolmentBodyStructure(data)
    )
    return response?.data
  } catch (error) {
    sentryLogger(error, 'error in booking enrolment with coins')
    throw error
  }
}

const bookEnrolmentWithRecharge = async (data) => {
  try {
    const makeRequestUrl = `${apiEndpoints.cartV2}`
    const response = await post(
      makeRequestUrl,
      bookEnrolmentWithRechargeBodyStructure(data)
    )
    return response?.data
  } catch (error) {
    sentryLogger(error, 'error in booking enrolment with coins')
    throw error
  }
}

export const getCartDetails = async (data) => {
  const makeRequestUrl = `${apiEndpoints.orders}/${data?.invoiceId}`
  try {
    const response = await get(makeRequestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching cart details')
    throw error
  }
}

export const getPlayCareEligibleGrades = async () => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: 'PLAY_CARE_ELIGIBLE_GRADES'
  })
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log(error, 'error in fetching deduction reasons')
    throw error
  }
}
