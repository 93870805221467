export const transformPlayCareTransactions = (data) => {
  return {
    ...data,
    results: data?.results?.map((transaction) => ({
      ...transaction,
      parent: {
        uuid: transaction?.user,
        parent_name: transaction?.coin_transaction?.enrolment?.parent_full_name
      }
    }))
  }
}
