import dayjs from 'dayjs'
import React from 'react'

import {
  commonAttributes,
  navigationPaths,
  sourceTypes,
  tableColumnDefaultOption
} from 'app/config'

export const columns = [
  // {
  //   name: 'uuid',
  //   label: 'Order ID'
  // },
  {
    ...commonAttributes.date,
    name: 'created',
    label: 'Order Date',
    options: {
      ...commonAttributes.date.options,
      customBodyRender: (val) => dayjs(val).format('DD MMM, YYYY'),
      filterKey: 'created_date'
    }
  },
  {
    name: 'created',
    label: 'Order Time',
    options: {
      disableFilter: true,
      customBodyRender: (val) => dayjs(val).format('HH:mm:ss')
    }
  },
  {
    ...commonAttributes.centre,
    name: 'coin_transaction.enrolment.batch_centre',
    label: 'Centre',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre_uuid'
    }
  },
  {
    name: 'parent',
    label: 'Parent Name',
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) =>
        value ? (
          <span>
            <a
              href={`${navigationPaths.parentdashboard}?parent=${value.uuid}`}>{`${value?.parent_name}`}</a>
          </span>
        ) : null,
      filterKey: 'parent_name'
    }
  },
  {
    name: 'coin_transaction.enrolment.parent_phone_number',
    label: 'Parent Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_phone_number'
    }
  },
  {
    name: 'coin_transaction.value',
    label: 'Total Cart Amount',
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) =>
        value ? <span>{parseFloat(value).toFixed(2)}</span> : null
    }
  },
  {
    name: 'total_paid.coins',
    label: 'Coins',
    options: {
      disableFilter: true,
      customBodyRender: (val) => (val ? val : '0')
    }
  },
  {
    name: 'coin_transaction.enrolment.source',
    label: 'source',
    widget: 'select',
    widgetFilter: true,
    isValueKey: true,
    valueLabelMappings: sourceTypes,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'booking_source'
    }
  }
]
