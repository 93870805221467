import { isEmpty } from 'lodash'
import { apiEndpoints } from './common'
import { eventAlert, makeRequestUrl } from '../app/helpers'
import { get, patch } from '../request'
import { sentryLogger } from 'sentry'
import constants from 'app/constants'


const fetchTeacherList = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.teacherList, queries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching teacher list')
    throw error
  }
}

const fetchStudentList = async ({ url, queries } = {}) => {

  const { teacher_uuid, ...restQueries } = queries || {}

  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.studentList}/${teacher_uuid}/`, restQueries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching student list')
    throw error
  }
}

const fetchChatMessages = async ({ url, queries } = {}) => {

  const { chat_uuid, ...restQueries } = queries || {}

  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.chatList}/${chat_uuid}/`, restQueries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching chat messages')
    throw error
  }
}

const fetchParentList = async ({ url, queries } = {}) => {

  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.parentList, queries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching parent list')
    throw error
  }
}

const fetchParentChatMessages = async ({ url, queries } = {}) => {

  const { chat_uuid, ...restQueries } = queries || {}

  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.chatList}/${chat_uuid}/`, restQueries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching chat messages')
    throw error
  }
}

const markConversationResolved = async (uuid) => {

  try {
    const response = await patch(`${apiEndpoints.chatChannel}/${uuid}/`, { conversation_status: 'resolved' })
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in marking conversation as resolved')
    eventAlert(error.message, constants.ERROR)
    // throw error
  }
}

const fetchUnreadCount = async ({ url, queries } = {}) => {

  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.unreadCount, queries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching unread count')
    throw error
  }
}

export {
  fetchTeacherList,
  fetchStudentList,
  fetchChatMessages,
  fetchParentList,
  fetchParentChatMessages,
  markConversationResolved,
  fetchUnreadCount
}
