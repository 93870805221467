/* eslint-disable react/prop-types */
import { Divider } from '@material-ui/core'
import dayjs, { Dayjs } from 'dayjs'
import _, { isEmpty, isNil, isNumber } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'

import {
  fetchUser,
  getCoinPrediction,
  fetchEnrolment,
  getExplorationStandards,
  getProgramsList,
  getProgramCost
} from 'api'
import ModalForm from 'app/components/ModalForm'
import { navigationPaths, enrolmentTypes } from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import { composeBatchDetails } from 'app/pages/Class/Inventory/transformers'
import {
  createClassBooking,
  createDemoClassBooking,
  createExcelBooking
} from 'app/store/actions/classEntity'
import { deletePropertyFromObject } from 'app/utils'

import { Actions } from './components/Actions'
import { CheckoutDetails } from './components/CheckoutDetails'
import CoursesDetail from './components/CoursesDetail'
import { InputForm } from './components/InputForm'
import { PricingDetails } from './components/PricingDetails'
import { demoSchema, classSchema } from './formConfig'
import {
  calculateDiscountPercentage,
  calculateTotalPrice,
  calculateDiscountedPrice
} from './formConfig/helpers'
import * as Styles from './styles'

import {
  batchTypesMap,
  BookingTypes,
  exploreMessageList
} from '../../../../constants'
import { SeparationContainer } from '../../../styles'
import {
  formatErrorMessage,
  getProgramEnrolments,
  validateBatchInfoDate,
  validateParentDetails,
  validateStudentDetails
} from '../helpers'

const LinkRedirector = ({ href }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ cursor: 'pointer' }}>
      here
    </a>
  )
}

const BookDemoClass = ({
  isModalOpen,
  bookingType,
  cartItems,
  onHandleDemoClassCloseClick,
  setIsBookDemoClassModalOpen,
  onBookingSuccess,
  onDeleteCartItem,
  selectedBatchType,
  setCartItems,
  setBookingType,
  isOnSpotBooking
}) => {
  const [isCheckoutMode, setIsCheckoutMode] = React.useState(false)
  const [formData, setFormData] = React.useState({})
  const [batchInfo, setBatchInfo] = React.useState({})
  const [errorSchema, setErrorSchema] = React.useState({})
  const [selectedParentDetails, setSelectedParentDetails] = React.useState({})
  const [selectedStudentDetails, setSelectedStudentDetails] = React.useState({})
  const [students, setStudents] = React.useState([])
  const [isOrderInProcessing, setIsOrderInProcessing] = React.useState(false)
  const [invalidCoins, setIsInvalidCoins] = React.useState(false)
  const [coinData, setCoinData] = React.useState({})
  const [commonCourses, setCommonCourses] = React.useState([])
  const [inputError, setInputError] = React.useState({
    parent: null,
    student: null
  })

  const [programEnrolments, setProgramEnrolments] = React.useState([])
  const [explorationGrades, setExplorationGrades] = React.useState([])
  const [programs, setPrograms] = React.useState([])
  const [selectedProgramData, setSelectedProgramData] = React.useState()
  const [isStartDateChanged, setIsStartDateChanged] = React.useState(false)
  const [isEndDateChanged, setIsEndDateChanged] = React.useState(false)
  const [disableConfirm, setDisableConfirm] = React.useState(true)
  const formRef = React.useRef(null)

  const { isPlayCare } = composeBatchDetails(cartItems?.[0])
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!cartItems.length) return
    handleSelectedParentChange()
  }, [cartItems.length])

  React.useEffect(() => {
    if (!cartItems.length) return

    const handler = () => {
      onBookingSuccess()
      setIsOrderInProcessing(false)
      onModalClose()
    }

    const errorHandler = () => {
      setIsOrderInProcessing(false)
    }

    eventEmitter.addListener(constants.INVENTORY_BOOKING_SUCCESS, handler)
    eventEmitter.addListener(constants.INVENTORY_BOOKING_ERROR, errorHandler)

    return () => {
      eventEmitter.removeListener(constants.INVENTORY_BOOKING_SUCCESS, handler)
      eventEmitter.removeListener(
        constants.INVENTORY_BOOKING_ERROR,
        errorHandler
      )
    }
  }, [cartItems])

  React.useEffect(() => {
    ;(async () => {
      const explorationGrades = await getExplorationStandards()
      const programList = await getProgramsList()
      setExplorationGrades(explorationGrades)
      setPrograms(programList)
    })()
  }, [])

  const getExplorerStatus = (programEnrolments, studentId) => {
    let status = ''
    const studentProgramEnrolments = programEnrolments.filter(
      (pe) => pe.student?.user_id === studentId
    )
    const isActive = studentProgramEnrolments.some((x) => x.status === 'active')
    const isUpcoming = studentProgramEnrolments.some(
      (x) => x.status === 'upcoming'
    )

    if (isActive) {
      status = 'active'
    } else if (isUpcoming) {
      status = 'upcoming'
    }

    return status
  }

  const handleSelectedParentChange = () => {
    if (!isEmpty(selectedParentDetails)) {
      setSelectedStudentDetails({})
      setCommonCourses({})
      const grades = []

      if (
        selectedParentDetails?.students?.length === 0 &&
        selectedBatchType !== 'explore'
      ) {
        eventAlert(
          'No students found! Add a student for the parent.',
          constants.ERROR
        )
      }

      const programEnrolledStudents = programEnrolments?.map(
        (item) => item?.student
      )

      const filteredBatch = cartItems?.map((item) => {
        const filteredGrades = item?.course?.standards?.forEach((std) => {
          if (grades.indexOf(std.name) === -1) grades.push(std.name)
        })
      })

      const filteredStudents = selectedParentDetails?.students.filter(
        (student) =>
          grades?.length ? grades.includes(student?.standard?.name) : true
      )

      const mappedstudents = selectedParentDetails?.students.map((student) => {
        const gradeDisability =
          grades.length && !grades.includes(student?.standard?.name)
        let exploreStatus = gradeDisability
        let message = null
        if (bookingType === batchTypesMap.exploration) {
          exploreStatus = getExplorerStatus(programEnrolments, student?.user_id)

          message = !explorationGrades.includes(student?.standard?.short_code)
            ? exploreMessageList.NOT_RELEVANT
            : !_.find(programEnrolledStudents, {
                user_id: student?.user_id
              })
            ? exploreMessageList.NOT_EXPLORER_YET
            : gradeDisability
            ? exploreMessageList.SESSION_NOT_RELEVANT
            : exploreStatus === 'upcoming'
            ? exploreMessageList.UPCOMING_EXPLORER
            : ''
        }

        return {
          ...student,
          isDisabled: gradeDisability || exploreStatus === null,
          message: message
        }
      })

      if (
        filteredStudents?.length === 0 &&
        selectedParentDetails?.students?.length > 0
      ) {
        eventAlert(
          'No students match the grade for the selected batch!',
          constants.ERROR
        )
      }

      setStudents(mappedstudents || [])

      handleValidateParentDetails(selectedParentDetails)

      const member_status = selectedParentDetails?.member_status
    } else {
      setStudents([])
      setSelectedStudentDetails({})
      setCommonCourses({})
      setInputError({
        parent: null,
        student: null,
        isDiscover: null
      })
      setDisableConfirm(true)
    }
  }

  React.useEffect(() => {
    ;(async () => {
      if (selectedParentDetails?.uuid) {
        const programEnrolments = await getProgramEnrolments(
          selectedParentDetails?.uuid
        )
        setProgramEnrolments(programEnrolments)
      }
    })()
    handleSelectedParentChange()
  }, [selectedParentDetails])

  React.useEffect(() => {
    if (isModalOpen) {
      cartItems.forEach((batch) => {
        const { price } = composeBatchDetails(batch)

        setBatchInfo((state) => {
          const batchId = batch.uuid

          const { firstTimeFee, fee, isDiscover } = state[batch.uuid] || {}

          let newState = {
            ...state
          }

          if (isNil(firstTimeFee)) {
            newState = {
              ...newState,
              [batchId]: {
                ...newState[batchId],
                firstTimeFee: 0
              }
            }
          }

          if (isNil(fee)) {
            newState = {
              ...newState,
              [batchId]: {
                ...newState[batchId],
                fee: price
              }
            }
          }

          if (isNil(isDiscover)) {
            newState = {
              ...newState,
              [batchId]: {
                ...newState[batchId],
                isDiscover: null
              }
            }
          }

          return newState
        })
      })
    }
  }, [isModalOpen, cartItems])

  const coinCalculation = async (batchData, student_uuid, isError) => {
    try {
      const coinPrediction = await getCoinPrediction(batchData)
      const enrollmentResp = await fetchEnrolment({
        queries: { student: student_uuid }
      })

      let tempCartItems = cartItems.map((batch) => {
        const coinSuggestion = coinPrediction.suggestions.find(
          (suggestion) => suggestion.batch === batch.uuid
        )
        const isSameSlotBooked =
          coinPrediction?.same_slot_already_booked ?? false
        const coinsNoOfSessions = coinSuggestion.sessions
        const coinsBySession = coinSuggestion.coins_per_session

        if (
          isSameSlotBooked ||
          (coinSuggestion.error_type &&
            coinSuggestion.error_type != 'session_conflict')
        ) {
          isError = true
          setDisableConfirm(true)
        }

        const batchStartDate = dayjs(coinSuggestion?.start_date).format(
          'DD MMM'
        )
        const batchEndDate = dayjs(coinSuggestion?.end_date).format('DD MMM')

        if (
          bookingType === BookingTypes.MEMBERSHIP &&
          selectedBatchType === 'membership'
        ) {
          const courseEnrolled = enrollmentResp.results
            .filter((el) => el.status === 'upcoming' || el.status === 'active')
            .map((item) => {
              return {
                uuid: item.batch.course.uuid,
                name: item.batch.course.name.split(' ')[0],
                start_date: dayjs(item.start_date, 'YYYY-MM-DD').format(
                  'D MMM'
                ),
                end_date: dayjs(item.end_date, 'YYYY-MM-DD').format('D MMM')
              }
            })
          const cartItemsCourse = cartItems.map((item) => {
            return item.course
          })

          const commonCourse = courseEnrolled.filter(
            (element) =>
              cartItemsCourse.findIndex((val) => val.uuid === element.uuid) >= 0
          )

          setCommonCourses(commonCourse)
        }

        return {
          ...batch,
          coinsTotal: coinSuggestion.coins_needed,
          coinsNoOfSessions: coinsNoOfSessions,
          coinsBySession: coinsBySession,
          coinPredictionError: formatErrorMessage(
            coinPrediction.error_message || coinPrediction.error_type,
            selectedStudentDetails?.user.first_name
          ),
          batchErrorType: coinSuggestion?.error_type ?? null,
          isSameSlotBooked: isSameSlotBooked,
          batchStartDate: batchStartDate,
          batchEndDate: batchEndDate,
          creditsApplied: coinSuggestion.credits_applied
        }
      })
      setCartItems(tempCartItems)
      setCoinData(coinPrediction)
      setIsInvalidCoins(isError)
    } catch (e) {
      setIsInvalidCoins(true)
      eventAlert(e.message, constants.ERROR)
    }
  }

  const costCalculation = async (batchData, student_uuid, isError) => {
    try {
      const costPrediction = await getProgramCost({ queries: batchData[0] })

      let tempCartItems = cartItems.map((batch) => {
        if (costPrediction.error_type) {
          isError = true
        }

        const batchStartDate = dayjs(costPrediction?.start_date).format(
          'DD MMM'
        )
        const batchEndDate = dayjs(costPrediction?.end_date).format('DD MMM')

        return {
          ...batch,
          coinsTotal: costPrediction.cost,
          coinsNoOfSessions: null,
          coinsBySession: null,
          coinPredictionError: formatErrorMessage(
            costPrediction.error_message || costPrediction.error_type,
            selectedStudentDetails?.user.first_name
          ),
          batchStartDate: batchStartDate,
          batchEndDate: batchEndDate,
          creditsApplied: null
        }
      })
      setCartItems(tempCartItems)
      setCoinData(costPrediction)
      setIsInvalidCoins(isError)
    } catch (e) {
      setIsInvalidCoins(true)
      eventAlert(e.message, constants.ERROR)
    }
  }

  const cartItemHandler = () => {
    if (!isEmpty(selectedStudentDetails)) {
      const isValid = handleValidateStudentDetails(selectedStudentDetails)
      if (isValid) {
        let isError = false
        const student_uuid = selectedStudentDetails?.user?.uuid
        const programData = programs.find(
          (el) => el.type === batchTypesMap.excel
        )
        setSelectedProgramData(programData)
        const batchData = cartItems.map((batch) => {
          const batchId = batch.uuid
          const batchDataObj =
            selectedBatchType === batchTypesMap.excel
              ? {
                  batch_uuid: batchId,
                  student_uuid: student_uuid,
                  start_date: batchInfo[batchId]?.startDate?.label,
                  end_date: batchInfo[batchId]?.endDate?.label,
                  program_uuid: programData?.uuid
                }
              : {
                  batch: batchId,
                  student: student_uuid,
                  parent: selectedStudentDetails?.parent,
                  type: selectedBatchType
                }

          return batchDataObj
        })
        ;(async () => {
          try {
            if (selectedBatchType === batchTypesMap.excel) {
              const { startDateStatus, endDateStatus } =
                validateBatchInfoDate(batchInfo)
              if (startDateStatus && endDateStatus) {
                await costCalculation(batchData, student_uuid, isError)
                setIsStartDateChanged(false)
                setIsEndDateChanged(false)
              }
            } else {
              await coinCalculation(batchData, student_uuid, isError)
            }
          } catch (e) {
            setIsInvalidCoins(true)
            eventAlert(e.message, constants.ERROR)
          }
        })()
      }
    }
  }

  React.useEffect(() => {
    cartItemHandler()
  }, [selectedStudentDetails])

  React.useEffect(() => {
    if (isStartDateChanged || isEndDateChanged) {
      cartItemHandler()
    }
  }, [isStartDateChanged, isEndDateChanged])

  const verifyDetails = async (parent_uuid) => {
    try {
      const { uuid } = selectedParentDetails || {}

      if (!isEmpty(uuid) || parent_uuid) {
        const queries = {
          uuid: parent_uuid ? parent_uuid : uuid
        }

        // call api again to get latest result
        const response = await fetchUser({ queries }, 'parent')

        if (response.results.length === 1) {
          const item = response.results[0]
          const { centre_preference } = item

          const {
            uuid,
            first_name,
            last_name,
            phone_number,
            email_address,
            state,
            coin_summary,
            member_status,
            credits
          } = item?.user

          // updated selected parent details
          setSelectedParentDetails({
            uuid,
            value: uuid,
            name: `${phone_number} (${first_name} ${last_name})`,
            first_name,
            last_name,
            phone_number,
            state,
            email: email_address,
            students: item.students,
            coins: coin_summary?.remaining_coins,
            credits,
            member_status,
            centre_preference
          })

          // update selected student details
          setSelectedStudentDetails((state) => {
            if (!isEmpty(state)) {
              let student =
                item.students.find(
                  (student) => student.user.uuid === state.user.uuid
                ) || {}
              student.parent = uuid
              return student
            }

            return state
          })
        } else {
          throw new Error('Multiple user found with same number')
        }
      }
    } catch (error) {
      console.log('Error in verifying user details :- ', error)
    }
  }

  const ReVerifyButton = () => {
    return (
      <>
        if updated, click{' '}
        <span
          onClick={verifyDetails}
          style={{
            textDecoration: 'underline',
            color: 'blue',
            cursor: 'pointer'
          }}>
          here
        </span>{' '}
        to verify
      </>
    )
  }

  const handleValidateParentDetails = (selectedParentDetails) => {
    let error = null,
      isValid = false

    if (isEmpty(selectedParentDetails)) {
      error = 'Please Select Parent'
    } else {
      const result = validateParentDetails(selectedParentDetails)

      isValid = result.isValid

      if (!isValid) {
        error = (
          <div>
            Parent details ({result.invalidAttributes.join(', ')}) invalid,
            click{' '}
            <LinkRedirector
              href={`${window.location.origin}${navigationPaths.parent}?uuid=${selectedParentDetails?.uuid}&editMode=1`}
            />{' '}
            to update, <ReVerifyButton />
          </div>
        )
      }
    }

    setInputError((state) => ({
      ...state,
      parent: error
    }))

    return isValid
  }

  const handleValidateStudentDetails = (selectedStudentDetails) => {
    let error = null,
      isValid = false

    if (isEmpty(selectedStudentDetails)) {
      error = 'Please Select Student'
    } else {
      const result = validateStudentDetails(selectedStudentDetails)

      isValid = result.isValid

      if (!isValid) {
        error = (
          <div>
            Student details ({result.invalidAttributes.join(', ')}) invalid,
            click{' '}
            <LinkRedirector
              href={`${window.location.origin}${navigationPaths.student}?uuid=${selectedStudentDetails?.user?.uuid}&editMode=1`}
            />{' '}
            to update, <ReVerifyButton />
          </div>
        )
      }
    }

    setInputError((state) => ({
      ...state,
      student: error
    }))

    return isValid
  }

  const handleValidateFirstTimeFee = (batchInfo) => {
    let isValid = false

    Object.values(batchInfo).forEach((batch) => {
      isValid =
        isNumber(batch.firstTimeFee) &&
        batch.firstTimeFee >= 0 &&
        batch.firstTimeFee <= batch.fee
    })

    return isValid
  }

  const handleValidateDiscovery = (batchInfo) => {
    let isValid = false
    let error = {}

    Object.keys(batchInfo).forEach((batch, index) => {
      let isDiscoverEnabled =
        cartItems[index].course?.subjects[0]?.discovery_enabled
      isValid = !isNil(batchInfo[batch].isDiscover) || !isDiscoverEnabled
      if (!isValid) {
        error[batch] = 'This is a required field'
      }
    })

    setInputError((state) => ({
      ...state,
      isDiscover: error
    }))

    return isValid
  }

  const heading = isCheckoutMode
    ? 'checkout'
    : bookingType === BookingTypes.MEMBERSHIP
    ? selectedBatchType === 'explore'
      ? isPlayCare
        ? 'oh. play'
        : 'Trial'
      : 'Membership'
    : bookingType === BookingTypes.DEMO
    ? 'Demo'
    : bookingType === BookingTypes.CLASS
    ? 'Class'
    : bookingType === BookingTypes.EXPLORATION
    ? 'Exploration'
    : bookingType === BookingTypes.EXCEL
    ? 'Excel'
    : null

  const formSchema =
    bookingType === BookingTypes.DEMO ||
    bookingType === BookingTypes.MEMBERSHIP ||
    bookingType === BookingTypes.EXPLORATION ||
    bookingType === BookingTypes.EXCEL
      ? demoSchema
      : bookingType === BookingTypes.CLASS
      ? classSchema
      : null

  const onNextClick = async () => {
    if (formRef.current) {
      // first validate extra input if its valid then submit form

      const isParentValid = handleValidateParentDetails(selectedParentDetails)
      const isStudentValid = handleValidateStudentDetails(
        selectedStudentDetails
      )
      const isFirstTimeFeeValid = handleValidateFirstTimeFee(batchInfo)
      const isDiscoverValid = handleValidateDiscovery(batchInfo)

      if (
        isParentValid &&
        isStudentValid &&
        isFirstTimeFeeValid &&
        isDiscoverValid
      ) {
        await formRef.current.submit()
        setErrorSchema({})
        setInputError({
          parent: null,
          student: null,
          isDiscover: null
        })
      }
    }
  }

  const onSubmitForm = ({ formData }) => {
    setIsCheckoutMode(true)
    setFormData(formData)
  }

  const handleOnFormDataChage = ({ formData }) => {
    if (formRef.current) {
      const { errorSchema } = formRef.current.validate(formData)
      const modifiedErrorSchema = {}

      Object.keys(errorSchema).forEach((key) => {
        if (formData[key]) {
          modifiedErrorSchema[key] = errorSchema[key]
        }
      })

      setErrorSchema(modifiedErrorSchema)
      setFormData(formData)
    }
  }

  const onEditClick = () => {
    setIsCheckoutMode(false)
  }

  const onConfirmClick = () => {
    setIsOrderInProcessing(true)
    if (bookingType === BookingTypes.CLASS) {
      dispatch(
        createDemoClassBooking({
          type: enrolmentTypes.CLASS,
          batchInfo,
          formData: {
            ...formData,
            discountPercentageValue: calculateDiscountPercentage({
              isDiscountInPercentage: formData.is_discount_in_percentage,
              discountPercentage: formData.discount_percentage,
              actualPrice: calculateTotalPrice(cartItems),
              customPricing: formData.custom_pricing
            })
          },
          userData: {
            student_uuid: selectedStudentDetails.user.uuid
          }
        })
      )
    } else if (bookingType === BookingTypes.EXCEL) {
      dispatch(
        createExcelBooking({
          student: selectedStudentDetails,
          program: selectedProgramData,
          batchInfo,
          batch_uuid: Object.keys(batchInfo)[0]
        })
      )
    } else {
      const isParentValid = handleValidateParentDetails(selectedParentDetails)
      const isStudentValid = handleValidateStudentDetails(
        selectedStudentDetails
      )

      if (isParentValid && isStudentValid) {
        dispatch(
          createClassBooking({
            type:
              bookingType === BookingTypes.MEMBERSHIP
                ? enrolmentTypes.MEMBERSHIP
                : bookingType,
            batchInfo,
            userData: {
              student_uuid: selectedStudentDetails.user.uuid,
              parent: selectedParentDetails.uuid
            }
          })
        )
      }
    }
  }

  const onModalClose = () => {
    setIsCheckoutMode(false)
    setFormData({})
    setBatchInfo({})
    setErrorSchema({})
    setSelectedParentDetails({})
    setSelectedStudentDetails({})
    setStudents([])
    setInputError({
      parent: null,
      student: null,
      isDiscover: null
    })
    onHandleDemoClassCloseClick()
    setCommonCourses({})
    setDisableConfirm(true)
  }

  const handleBatchInfoChange = (batchId, property, value) => {
    setBatchInfo((state) => {
      return {
        ...state,
        [batchId]: {
          ...state[batchId],
          [property]: value
        }
      }
    })
    if (property === 'startDate') {
      setIsStartDateChanged(true)
    }

    if (property === 'endDate') {
      setIsEndDateChanged(true)
    }
  }

  const handleParentSelection = (name, value) => {
    setSelectedParentDetails(value || {})
    verifyDetails(value?.uuid)
  }

  const handleStudentSelection = (event) => {
    const { value } = event.target
    const selectedStudent = students.filter(
      (item) => item.user.uuid === value
    )[0]
    let studentDetails = !isEmpty(selectedStudent) ? selectedStudent : {}
    if (!isEmpty(selectedStudent)) {
      studentDetails.parent = selectedParentDetails.uuid
    }
    setSelectedStudentDetails(studentDetails)
    setDisableConfirm(false)
  }

  const onDeleteSelectedBatch = (batch) => {
    setBatchInfo((state) => {
      return deletePropertyFromObject(batch.uuid, state)
    })

    // deselectBatch(batch)
    onDeleteCartItem(batch)
  }

  const onEditSelectedBatch = () => {
    setIsBookDemoClassModalOpen(false)
  }

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      enableSave={false}
      showCloseIcon
      onModalClose={onModalClose}>
      <Styles.Container>
        <Styles.Heading variant="h6">{heading}</Styles.Heading>
        <Styles.FormContainer>
          {isCheckoutMode ? (
            <CheckoutDetails
              cartItems={cartItems}
              schema={formSchema}
              formData={formData}
              selectedParentDetails={selectedParentDetails}
              selectedStudentDetails={selectedStudentDetails}
              bookingType={bookingType}
            />
          ) : (
            <InputForm
              ref={formRef}
              bookingType={bookingType}
              schema={formSchema}
              onSubmit={onSubmitForm}
              formData={formData}
              onChangeForm={handleOnFormDataChage}
              errorSchema={errorSchema}
              selectedParentDetails={selectedParentDetails}
              selectedStudentDetails={selectedStudentDetails}
              handleParentSelection={handleParentSelection}
              handleStudentSelection={handleStudentSelection}
              students={students}
              inputError={inputError}
              setSelectedParentDetails={setSelectedParentDetails}
              verifyDetails={verifyDetails}
              allowAddingNewUser={isOnSpotBooking}
              selectedBatch={cartItems?.[0]}
            />
          )}

          <SeparationContainer $css={`flex:0.05;`}>
            <Divider orientation="vertical" />
          </SeparationContainer>
          <Styles.ItemsContainer>
            <CoursesDetail
              cartItems={cartItems}
              isCheckoutMode={isCheckoutMode}
              bookingType={bookingType}
              deselectBatch={onDeleteSelectedBatch}
              onEditClick={onEditSelectedBatch}
              batchInfo={batchInfo}
              onBatchInfoChange={handleBatchInfoChange}
              selectedBatchType={selectedBatchType}
              coinData={coinData}
              commonCourse={commonCourses}
              showEditOptions={isOnSpotBooking}
              errorSchema={inputError}
            />
          </Styles.ItemsContainer>
        </Styles.FormContainer>

        <PricingDetails
          cartItems={cartItems}
          formData={formData}
          batchInfo={batchInfo}
          bookingType={bookingType}
          selectedParentDetails={selectedParentDetails}
          selectedBatchType={selectedBatchType}
        />
      </Styles.Container>

      <Actions
        isCheckoutMode={isCheckoutMode || isOnSpotBooking}
        onNextClick={onNextClick}
        onEditClick={onEditClick}
        onConfirmClick={onConfirmClick}
        isOrderInProcessing={isOrderInProcessing}
        invalidCoins={invalidCoins}
        commonCourse={commonCourses}
        disableConfirm={disableConfirm}
        showEditOption={isOnSpotBooking}
      />
    </ModalForm>
  )
}

export default BookDemoClass
