import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchPlayCareTransactionsData } from 'app/store/actions/ordersEntity'

import { columns } from './config'

const PlayCareOrders = () => {
  const playCareOrdersData = useSelector(
    (state) => state.ordersEntityReducer.playCareOrdersData
  )

  return (
    <>
      <BaseEntity
        entity={constants.PLAY_CARE_ORDERS}
        label={constants.PLAY_CARE_ORDERS_LABEL}
        fetchData={fetchPlayCareTransactionsData}
        data={playCareOrdersData}
        columns={columns}
        readOnly
      />
    </>
  )
}

export default PlayCareOrders
