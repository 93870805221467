import dayjs from 'dayjs'
import Dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { isEmpty, isNil } from 'lodash'

Dayjs.extend(customParseFormat)

export const composeBatchDetails = (selectedSchedule) => {
  if (!selectedSchedule) return {}

  const {
    uuid,
    name: batchName,
    centre,
    enrolment_capacity: enrolmentCapacity,
    course,
    teacher,
    cadence: alternateBatches,
    start_time: startTime,
    end_time: endTime,
    end_date,
    start_date,
    fee,
    vacancy,
    schedule_event,
    type,
    level,
    coinsTotal,
    coinsNoOfSessions,
    coinsBySession,
    batchStartDate,
    batchEndDate,
    creditsApplied,
    coinPredictionError,
    batchErrorType,
    isSameSlotBooked,
    coins_per_month,
    is_play_care: isPlayCare
  } = selectedSchedule

  const { name: centreName, city } = centre || {}

  const {
    name: courseName,
    details,
    min_age: minAge,
    max_age: maxAge,
    subjects,
    standards,
    coins_per_session: coinsPerSession
  } = course || {}

  const { full_name: teacherName } = teacher || {}

  const noOfClasses = schedule_event.schedules.length

  const durationInDays = Dayjs(end_date).diff(Dayjs(start_date), 'days')
  const weeks = dayjs.duration({ days: durationInDays }).asWeeks()

  const durationInWeeks = Math.ceil(weeks)

  const end_time = Dayjs(alternateBatches[0].end_time, 'HH-mm', false)
  const start_time = Dayjs(alternateBatches[0].start_time, 'HH-mm', false)
  const totalMins = end_time.diff(start_time, 'm')

  const totalHoursPerSession = totalMins / 60

  const totalHours = noOfClasses * (totalMins / 60)

  const duration = Math.min(durationInWeeks, 4) || 1

  const totalSessions =
    type === 'membership' ? alternateBatches.length * duration : noOfClasses

  return {
    uuid,
    batchName,
    courseName,
    teacherName,
    details,
    location: `${centreName}${city ? `, ${city}` : ''}`,
    enrolmentCapacity,
    price: fee,
    coins: coinsPerSession,
    coins_monthly: coins_per_month,
    subject: subjects[0],
    vacancy,
    ...(startTime && endTime
      ? {
          timing: `${Dayjs(startTime, 'HH:mm:ss', true).format('h:mm')}-${Dayjs(
            endTime,
            'HH:mm:ss',
            true
          ).format('h:mm A')}`
        }
      : null),
    ...(!isEmpty(alternateBatches)
      ? {
          alternateBatches: alternateBatches.map(
            (time) =>
              `${Dayjs()
                .day(time.weekday + 1)
                .format('ddd')} ${Dayjs(time.start_time, 'HH-mm', false).format(
                'h:mm'
              )}-${Dayjs(time.end_time, 'HH-mm', false).format('h:mm A')}`
          )
        }
      : null),
    ...(minAge && maxAge ? { ages: `${minAge}-${maxAge}` } : null),
    standards,
    durationInWeeks,
    totalHoursPerSession,
    totalHours,
    type,
    level,
    totalSessions,
    coinsTotal,
    coinsNoOfSessions,
    coinsBySession,
    batchStartDate,
    batchEndDate,
    creditsApplied,
    coinPredictionError,
    batchErrorType,
    isPlayCare,
    isSameSlotBooked,
    subjectsName: subjects.map((subject) => subject.name).join(' • '),
    subjectsDetail: subjects.map((subject) => subject.description).join('\n')
  }
}

export const coinCalculationBodyStructure = (data) => {
  const { parent, cart_type } = data
  let body = { cart_type, user: parent }
  switch (cart_type) {
    case 'booking':
      body = { ...body, ...paymentOptionsItemsStructure(data?.cartItems) }
      break

    default:
      break
  }
  return body
}

export const categoryCostBodyStructure = (data) => {
  const {
    batch,
    startDate: { label: startDateLabel },
    isDiscover
  } = data

  return {
    batch,
    start_date: startDateLabel,
    product: isDiscover === true ? 'discovery' : 'subscription'
  }
}

export const paymentOptionsItemsStructure = (items) => {
  return {
    items: items.map((item) => {
      const product =
        item?.batchFormInfo.isDiscover === true ? 'discovery' : 'subscription'
      const reason =
        item?.batchFormInfo.isDiscover === true
          ? 'Discovery booking'
          : 'Subscription'
      const mappedItem = {
        product,
        reason,
        cost_price: item?.price,
        batch: item?.batchDetails?.uuid,
        student: item.userInfo.student.user.uuid,
        start_date: item?.batchFormInfo?.startDate?.label,
        end_date: item?.batchFormInfo?.endDate?.value,
        subject: item?.batchDetails?.course?.subjects?.[0]?.uuid
      }
      return mappedItem
    })
  }
}

export const bookEnrolmentBodyStructure = (data) => {
  const structure = {
    ...(data?.useCoins ? { coin_payment: data?.coinBalance } : null),
    user: data.parent,
    type: 'booking',
    payment_mode: data.payment_mode,
    notes: 'Purchase',
    value: data.cartTotal,
    items: data.cartItems.map((item) => ({
      cost_price: item.price,
      reason: item.batchFormInfo.isDiscover
        ? 'Discovery booking'
        : 'Subscription',
      product: item?.batchFormInfo?.isDiscover ? 'discovery' : 'subscription',
      batch: item?.batchDetails?.uuid,
      student: item.userInfo.student.user.uuid,
      start_date: item?.batchFormInfo?.startDate?.label,
      end_date: item?.batchFormInfo?.endDate?.value,
      subject: item?.batchDetails?.course?.subjects?.[0]?.uuid,
      subject_name: item?.batchDetails?.course?.subjects?.[0]?.name
    })),
    ...(data.reason_for_using_alfred !== '' &&
    !isNil(data.reason_for_using_alfred)
      ? { reason_for_using_alfred: data.reason_for_using_alfred }
      : {})
  }
  if (data?.summary?.admission_fee > 0) {
    structure.items.push({
      cost_price: data?.summary?.admission_fee,
      discount: 0,
      reason: 'Admission',
      product: 'admission'
    })
  }
  return structure
}

export const bookEnrolmentWithRechargeBodyStructure = (data) => {
  const structure = {
    user: data.uuid,
    type: 'topup_booking',
    payment_mode: 'rupees',
    notes: 'Purchase coins and do booking',
    value: data.coins,
    items: data.cartItems.map((item) => ({
      cost_price: item.price,
      reason: item.batchFormInfo.isDiscover
        ? 'Discovery booking'
        : 'Subscription',
      product: item?.batchFormInfo?.isDiscover ? 'discovery' : 'subscription',
      batch: item?.batchDetails?.uuid,
      student: item.userInfo.student.user.uuid,
      start_date: item?.batchFormInfo?.startDate?.label,
      end_date: item?.batchFormInfo?.endDate?.value,
      subject: item?.batchDetails?.course?.subjects?.[0]?.uuid,
      subject_name: item?.batchDetails?.course?.subjects?.[0]?.name
    })),
    ...(data.reason_for_using_alfred !== '' &&
    !isNil(data.reason_for_using_alfred)
      ? { reason_for_using_alfred: data.reason_for_using_alfred }
      : {})
  }

  structure.items.push({
    cost_price: data.coins,
    discount: data.discount_percentage,
    reason: 'Recharge',
    product: 'coins'
  })
  if (data?.summary?.admission_fee > 0) {
    structure.items.push({
      cost_price: data?.summary?.admission_fee,
      discount: 0,
      reason: 'Admission',
      product: 'admission'
    })
  }
  return structure
}
