import * as types from '../types/ordersEntity'

export const fetchOrdersData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_ORDERS_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchOrdersDataSuccess = (value, entity) => ({
  type: types.FETCH_ORDERS_DATA_SUCCESS,
  value,
  entity
})

export const fetchPlayCareTransactionsData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_PLAY_CARE_TRANSACTIONS_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchPlayCareTransactionsDataSuccess = (value, entity) => ({
  type: types.FETCH_PLAY_CARE_TRANSACTIONS_DATA_SUCCESS,
  value,
  entity
})
