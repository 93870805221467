import { takeLatest } from 'redux-saga/effects'

import { fetchOrders, fetchPlayCareTransactions } from 'api/orders'
import constants from 'app/constants'
import * as types from 'app/store/types/ordersEntity'

import { handleFetch } from './saga.helper'

import {
  fetchOrdersData,
  fetchOrdersDataSuccess,
  fetchPlayCareTransactionsData,
  fetchPlayCareTransactionsDataSuccess
} from '../actions/ordersEntity'

const mapping = {
  [constants.ORDERS]: {
    api: {
      fetch: fetchOrders
    },
    action: {
      fetch: fetchOrdersData,
      fetchSuccess: fetchOrdersDataSuccess
    }
  },
  [constants.PLAY_CARE_ORDERS]: {
    api: {
      fetch: fetchPlayCareTransactions
    },
    action: {
      fetch: fetchPlayCareTransactionsData,
      fetchSuccess: fetchPlayCareTransactionsDataSuccess
    }
  }
}

export function* _fetchOrdersEntity({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* watchFetchOrdersEntity() {
  yield takeLatest(
    [types.FETCH_ORDERS_DATA, types.FETCH_PLAY_CARE_TRANSACTIONS_DATA],
    _fetchOrdersEntity
  )
}
